// src/components/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.scss';

const HomePage = () => {
  return (
    <div className='home-page'>
      <h1 className='title'>POLSKA 2024</h1>
      <p className='description'><strong>Witaj w Polsce!</strong><br/><br/>
        Mamy 2024 rok. Sporo działo się w trakcie ostatniej kadencji. Pandemia, kryzys gospodarczy, afery polityczne...
        Jednak w myśl zasady „Nowy rok, nowa ja” Polska się nie poddaje - to czas na zmiany!
        Nie jedna nadzieja na transformację skończyła się jednak bolesną porażką, dlatego Polska potrzebuje kogoś to potrafi podejmować trudne decyzje - kogoś kto nie boi się walczyć o lepsze jutro…
        Polska potrzebuje Ciebie…
        Czy weźmiesz na swoje barki ciężar związany z pełnieniem funkcji lidera tego kraju!
        Zatem rozsiądź się wygodnie w swoim gabinecie, Prezesie...
      </p>
      <a href='https://play.google.com/store/apps/details?id=com.michalz0001.Polska2024'><button className='download-btn'>Pobierz z Google Play</button></a>
      <Link to='/privacy-policy' className='privacy-policy-link'>Polityka prywatności</Link>
    </div>
  );
}

export default HomePage;
