import React from 'react'
import './PrivacyPolicy.scss'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy'>
            <h1>Polityka prywatności dla gry „Polska2024”</h1>
            <p>Oto nasza polityka prywatności dla aplikacji mobilnej „Polska2024”, dostępnej w sklepie Google Play. Ta polityka określa, w jaki sposób gromadzamy, używamy i chronimy dane użytkowników tej aplikacji.</p>

            <h2>Gromadzenie i rodzaje danych:</h2>
            <p>Aplikacja „Polska2024” gromadzi jedynie niezbędne dane, które są wymagane do zapewnienia funkcjonalności gry oraz poprawy jakości doświadczenia użytkownika.
            Automatycznie zbierane dane mogą obejmować informacje techniczne o urządzeniu (takie jak model urządzenia, wersja systemu operacyjnego, unikalne identyfikatory), dane dotyczące lokalizacji (jeśli użytkownik wyrazi na to zgodę) oraz dane dotyczące sposobu korzystania z aplikacji.</p>
            <h2>Cel gromadzenia danych:</h2>
            <p>Dane są gromadzone w celu zapewnienia działania aplikacji oraz optymalizacji jej funkcji pod kątem potrzeb użytkowników.
            Informacje o lokalizacji mogą być wykorzystywane do dostarczania lokalnie dostosowanych treści, takich jak ustawienia regionalne w grze.</p>
            <h2>Udostępnianie danych osobom trzecim:</h2>
            <p>Dane zbierane przez aplikację „Polska2024” nie są udostępniane osobom trzecim ani sprzedawane.
            Wyjątkiem może być udostępnienie danych zgodnie z obowiązującymi przepisami prawnymi lub na żądanie organów ścigania.</p>
            <h2>Bezpieczeństwo danych:</h2>
            <p>Dbamy o bezpieczeństwo danych użytkowników poprzez stosowanie odpowiednich środków technicznych i organizacyjnych zapewniających ochronę przed nieautoryzowanym dostępem, utratą lub kradzieżą danych.</p>
            <h2>Zgoda użytkownika:</h2>
            <p>Użytkownik może wyrazić zgodę na gromadzenie określonych danych, takich jak lokalizacja, w ustawieniach aplikacji.
            Użytkownik ma możliwość zmiany lub wycofania udzielonej zgody w dowolnym momencie poprzez ustawienia urządzenia.</p>
            <h2>Kontakt:</h2>
            <p>W razie pytań dotyczących polityki prywatności lub zarządzania danymi użytkowników, prosimy o kontakt na adres e-mail: michalzareba0001@gmail.com
            Ta polityka prywatności obowiązuje od 06-04-2024 i może być okresowo aktualizowana. Prosimy o regularne sprawdzanie naszej polityki prywatności w celu zapoznania się z ewentualnymi zmianami.</p>
            <Link to='/' className='back-link'>Wróć</Link>
        </div>
    )
}

export default PrivacyPolicy